<template>
    <div class="board-container">
        <report-list-categories v-if="code == 'report'" />
        <table class="board-list">
            <thead class="d-none d-lg-table-header">
                <tr>
                    <th style="width: 10%" class="board-list__txt d-none d-lg-table-cell">{{ $t("common.number") }}</th>
                    <th v-if="code == 'report'" style="width: 20%" class="board-list__tit">{{ $t("common.카테고리") }}</th>
                    <th style="width: 50%" class="board-list__tit">{{ $t("common.제목") }}</th>
                    <th style="width: 15%" class="board-list__txt">{{ $t("common.작성자") }}</th>
                    <th style="width: 15%" class="board-list__txt">{{ $t("common.작성일") }}</th>
                    <th style="width: 10%" class="board-list__txt">{{ $t("common.조회수") }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(notice, index) in notices" :key="`notice-${index}`" @click="showBoard(notice)">
                    <td align="center" class="board-list__txt d-none d-lg-table-cell">
                        <v-icon>mdi-alert-circle-outline</v-icon>
                    </td>
                    <td v-if="code == 'report'" align="center" class="board-list__txt d-none d-lg-table-cell">
                        <div class="ellip">
                            <span>{{ categories[notice.category]?.text || "" }}</span>
                        </div>
                    </td>
                    <td align="center" class="board-list__tit">
                        <div class="ellip">
                            <span>{{ notice.subject }}</span>
                        </div>
                    </td>
                    <td align="center" class="board-list__txt board-list__txt--first">
                        {{ notice.writer.name }}
                    </td>
                    <td align="center" class="board-list__txt">
                        {{ notice.createdAt.toDate() }}
                    </td>
                    <td align="center" class="board-list__txt">
                        {{ notice.viewCount }}
                    </td>
                </tr>
                <tr v-for="(board, index) in boards" :key="`board-${index}`" @click="showBoard(board)">
                    <td align="center" class="board-list__txt d-none d-lg-table-cell">
                        <span>{{ summary.totalCount - skip - index }}</span>
                    </td>
                    <td v-if="code == 'report'" align="center" class="board-list__txt d-none d-lg-table-cell">
                        <div class="ellip">
                            <span>{{ categories[board.category]?.text || "" }}</span>
                        </div>
                    </td>
                    <td align="center" class="board-list__tit">
                        <div class="ellip">
                            <span>{{ board.subject }}</span>
                        </div>
                    </td>
                    <td align="center" class="board-list__txt board-list__txt--first">
                        {{ board.writer.name }}
                    </td>
                    <td align="center" class="board-list__txt">
                        {{ board.createdAt.toDate() }}
                    </td>
                    <td align="center" class="board-list__txt">
                        {{ board.viewCount }}
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="board-bottom">
            <v-row v-show="false" justify="end" class="row--x-small">
                <v-col cols="auto">
                    <v-btn color="primary" class="w-100px" @click="$router.push('?mode=input')">{{ $t("common.writing") }}</v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn outlined color="grey-d6" class="w-140px" @click="$emit('loadmore')">{{ $t("common.list") }}</v-btn>
                </v-col>
            </v-row>
            <v-row v-show="!hideWriteButton" justify="end" class="row--x-small">
                <v-col cols="auto">
                    <v-btn color="primary" class="w-100px" @click="$router.push(`${$route.path}/write`)">{{ $t("common.writing") }}</v-btn>
                </v-col>
            </v-row>
        </div>

        <pagination-component :count="count" :value="page" @change="changePage" />
    </div>
</template>

<script>
import PaginationComponent from "@/components/client/control/pagination-component.vue";
import ReportListCategories from "./report-list-categories.vue";
import { BOARD_REPORT_CATEGORIES } from "@/assets/variables";

export default {
    components: {
        PaginationComponent,
        ReportListCategories,
    },

    props: {
        code: { type: String, default: null },
        summary: {
            type: Object,
            default() {
                return { totalCount: 0 };
            },
        },
        boards: { type: Array, default: null },
        notices: { type: Array, default: null },
        page: { type: Number, default: 1 },
        skip: { type: Number, default: 0 },
        limit: { type: Number, default: null },
        hideWriteButton: { type: Boolean, default: false },
    },
    methods: {
        changePage(page) {
            this.$emit("page", page);
        },
        showBoard(board) {
            this.$router.push(`${this.$route.path}/${board._id}`);
        },
    },
    computed: {
        count() {
            return Math.ceil(this.summary.totalCount / this.limit);
        },
        categories() {
            if (this.code == "report") return BOARD_REPORT_CATEGORIES;
            return {};
        },
    },
};
</script>